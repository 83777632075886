import React from 'react'
import image from "../Assets/News/image 1.png";
import banner from "../Assets/News/banner.png";
import '../Style/News.scss';
import { useEffect, useState } from 'react';
import rect from "../Assets/News/Rectangle 8.png"
import { Button } from 'react-bootstrap';
import $ from "jquery"
import Axios from 'axios';
import api from '../Config/conf';
import Number from './Pagination';
import { motion } from "framer-motion"
function News() {
    const [Products, setProducts] = useState([]);
    const [Events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [page1, setPage1] = useState(1);
    const [nrofpages, setNumberofpage] = useState(1);
    const [nrofpages1, setNumberofpage1] = useState(1);
    useEffect(() => {
        Axios.get("https://mataramlaw-cms.com/wp-json/wp/v2/news?per_page=6", {
            params: { page: page }
        }).then(response => {
            setNumberofpage(response.headers["x-wp-totalpages"]);
            // Store the posts from the response.
            setProducts(response.data);
        });
    }, [page, setProducts]);
    console.log(Products)

    useEffect(() => {
        Axios.get("https://mataramlaw-cms.com/wp-json/wp/v2/events?per_page=6", {
            params: { page1: page1 }
        })
            .then(res => {
                setNumberofpage1(res.headers["x-wp-totalpages"]);
                setEvents(res.data)
            });
    }, [page1, setEvents]);
    console.log(Events)
    const scrollNews = () => {
        $("html, body").animate({
            scrollTop: $("#News").offset().top - 20
        }, 100);
    }
    const scrollEvents = () => {
        $("html, body").animate({
            scrollTop: $("#Events").offset().top - 20
        }, 100);
    }
    return (
        <div className='News-Container'>
            <div className='News-Section-1'>
                <motion.img
                    initial={{
                        opacity: 0
                    }}
                    whileInView={{
                        opacity: 1
                    }}
                    transition={{
                        duration: 0.5,
                        delay: 0.2
                    }}
                    viewport={{
                        once: true
                    }}
                    src={banner} />
                <div className='News-Content'>
                    <motion.h1
                        initial={{
                            opacity: 0,
                            y: 100
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.4
                        }}
                        viewport={{
                            once: true
                        }}
                    >Resources</motion.h1>
                    <motion.p
                        initial={{
                            opacity: 0,
                            y: 100
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.6
                        }}
                        viewport={{
                            once: true
                        }}
                    >Mataram Partners enjoy a privileged position when it comes to resourcing valuable information. We are committee to sharing this information and keeping clients informed on the latest developments in the region.</motion.p>
                    <motion.span
                        initial={{
                            opacity: 0,
                            y: 100
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.8
                        }}
                        viewport={{
                            once: true
                        }}
                    >To learn more about Mataram Partners expertise:</motion.span>
                    <motion.div
                        initial={{
                            opacity: 0,
                        }}
                        whileInView={{
                            opacity: 1,
                        }}
                        transition={{
                            duration: 1,
                            delay: 0.9
                        }}
                        viewport={{
                            once: true
                        }}
                        className='Button-Container'>
                        <Button onClick={scrollNews}>
                            NEWS
                        </Button>
                        <Button onClick={scrollEvents}>
                            EVENTS
                        </Button>
                    </motion.div>
                </div>
            </div>
            <div className='News-Section-2' id='News'>
                <div className='Title-Section'>
                    <motion.h2
                        initial={{
                            opacity: 0,
                            y: 100
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.2
                        }}
                        viewport={{
                            once: true
                        }}
                    >NEWS</motion.h2>
                </div>

                <div className='Card-Section'>
                    {
                        Products && Products.map((props) => {
                            // const title = props.title.rendered
                            return (
                                <div key={props.id} className='Card'>
                                    <div className='Image-Container'>
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                y: 100
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.2
                                            }}
                                            viewport={{
                                                once: true
                                            }}
                                            src={`https://mataramlaw-cms.com/wp-content/uploads/2023/03/${props.acf.image.filename}`} />
                                    </div>
                                    <div className='Title-Container'>
                                        <motion.a
                                            initial={{
                                                opacity: 0,
                                                y: 100
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.4
                                            }}
                                            viewport={{
                                                once: true
                                            }}
                                            href={`/news/${props.id}`}>
                                            <h2>
                                                {props.acf.news_title}
                                            </h2>
                                        </motion.a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Number
                    nrOfPages={nrofpages}
                    currentpage={page}
                    onSelectPage={n => {
                        setPage(n);
                    }}
                />
            </div>
            <div className='News-Section-3'>
                <motion.span
                    initial={{
                        width: "0%"
                    }}
                    whileInView={{
                        width: "100%"
                    }}
                    transition={{
                        duration: 1,
                        delay: 0.2
                    }}
                    viewport={{
                        once: true
                    }}
                    className='Line' />
                <div id='Events' className='Event-Container' >
                    <motion.h2
                        initial={{
                            opacity: 0,
                            y: 100
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.2
                        }}
                        viewport={{
                            once: true
                        }}
                    >EVENTS</motion.h2>
                    <div className='Event-Content'>
                        {
                            Events && Events.map(props => {
                                return (
                                    <div className='Card' key={props.id}>
                                        <motion.img
                                            initial={{
                                                opacity: 0
                                            }}
                                            whileInView={{
                                                opacity: 1
                                            }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.2
                                            }}
                                            viewport={{
                                                once: true
                                            }} src={rect} />
                                        <div className='Content'>
                                            <motion.h3
                                                initial={{
                                                    opacity: 0,
                                                    x: 100
                                                }}
                                                whileInView={{
                                                    opacity: 1,
                                                    x: 0
                                                }}
                                                transition={{
                                                    duration: 0.5,
                                                    delay: 0.4
                                                }}
                                                viewport={{
                                                    once: true
                                                }}
                                            >{props.title.rendered}</motion.h3>
                                            <motion.span
                                                initial={{
                                                    opacity: 0,
                                                    x: 100
                                                }}
                                                whileInView={{
                                                    opacity: 1,
                                                    x: 0
                                                }}
                                                transition={{
                                                    duration: 0.5,
                                                    delay: 0.6
                                                }}
                                                viewport={{
                                                    once: true
                                                }}
                                            >{props.acf.date}</motion.span>
                                            <motion.p
                                                initial={{
                                                    opacity: 0,
                                                    x: 100
                                                }}
                                                whileInView={{
                                                    opacity: 1,
                                                    x: 0
                                                }}
                                                transition={{
                                                    duration: 0.5,
                                                    delay: 0.8
                                                }}
                                                viewport={{
                                                    once: true
                                                }}
                                            >{props.acf.desc}</motion.p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default News